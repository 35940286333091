import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/nuovologo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleLinkClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolla verso l'alto quando si cambia la route
  };
  
  return (

    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <Link to="/">
            <img
              src={logo}
              alt="Indiefetto"
              className="w-16 m-5 rounded-full hover:scale-105"
            />
          </Link>
        </div>
        
        <div className="md:flex md:space-x-6 md:mt-0 md:mb-4">
        <div className="mb-5">
            <h4 className="mb-2 text-lg font-semibold text-punk">
              Sitemap
            </h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleLinkClick("/")}
                  className="text-white hover:text-primarypink pb-2"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleLinkClick("/portfolio")}
                  className="text-white hover:text-primarypink pb-2"
                >
                  Portfolio
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleLinkClick("/servizi")}
                  className="text-white hover:text-primarypink pb-2"
                >
                  Servizi
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleLinkClick("/libro")}
                  className="text-white hover:text-primarypink pb-2"
                >
                  Il mio libro
                </button>
              </li>
            </ul>
          </div>
          <div className="md:col-span-2">
            <h4 className="mb-2 text-lg font-semibold text-punk">Contatti</h4>
            <p className="text-gray-300">
              postmaster@indiefetto.it
              <br />
              <button
                onClick={() => handleLinkClick("/contatti")}
                className="block text-white mx-auto hover:text-primarypink pb-2"
              >
                Compila il form
              </button>
            </p>
            <div className="footer-icons text-primarypink">
              <a
                href="https://www.facebook.com/alessia.gaudioso"
                aria-label="Facebook profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 hover:text-gray-100 hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v-3.385z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/evitolaforma"
                aria-label="Instagram profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 float-left hover:text-gray-100 hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/indiefetto"
                aria-label="LinkedIn profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 hover:text-gray-100 hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </a>
            </div>
          </div>
          <div>
          <h4 className="mt-5 md:mt-0 mb-2 text-lg font-semibold text-punk">Assistenza</h4>
          <button
                  onClick={() => handleLinkClick("/policy")}
                  className="text-white hover:text-primarypink pb-2"
                >
                  Privacy e cookie policy
                </button>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 py-3 mt-3">
        <div className="container mx-auto px-5 flex flex-wrap flex-col sm:flex-row justify-between items-center">
          <p className="text-gray-300 text-sm text-center sm:text-left">
          © {currentYear} <span className="text-[#efd2d6]">Alessia Gaudioso</span>.
            Tutti i diritti riservati.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
