import React from "react";
import "../App.css";
import studio from "../img/alex2.webp";
import "../style/general-module.css";

const About = () => {
  return (
    <div className="max-w-lg rounded overflow-hidden shadow-lg mx-auto mb-10 bg-white">
       <img
        src={studio}
        alt="Alessia Gaudioso"
        className="w-48 rounded-full mx-auto"
      />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">About me</div>
        <div className="text-gray-700">
          <h1 className="text-3xl font-bold mb-4">Hi there!</h1>
          <p className="text-base mb-6">
            Alessia, scritta nel 1985. Torinese di nascita, e torinese/sarda nel
            cuore.
          </p>
          <p className="text-base mb-6">
            Sono una scrittrice per esigenza e da poco una sviluppatrice web. La
            mia idea è quella di unire queste due grandi passioni per generare
            una forza unica che mi somigli il più possibile e poter portare
            passione e creatività in tutto ciò che faccio.
          </p>
          <p className="text-base mb-6">
            Amo passeggiare da sola, leggere, la musica indie, rock, urban, il
            cantautorato italiano (vecchio e nuovo), il cinema, il calcio,
            l'odore della pioggia, camminare a piedi nudi e mordere il culo
            delle fragole.
          </p>
          <p className="text-base mb-6">
            Da anni pratico yoga, meditazione e cristalloterapia. Tiro bei pugni
            e bei calci di rigore, ma con la penna blu in mano forse sto più a
            mio agio. In 38 anni tante cose ho scritto e ne sono rimaste poche,
            mi piace definirmi un'appallottolatrice seriale: gran parte dei miei
            pensieri sono finiti nel cestino della carta.
          </p>

          <p className="text-base mb-6">
            Il codice mi sta aiutando a creare, per una volta, qualcosa che
            resti.
          </p>
          <p className="text-base">
            Grazie a chi è già qui e grazie a chi arriverà.
          </p>
          <div className="mt-4">
            <p className="text-lg font-semibold">Love,</p>
            <p className="text-2xl">A.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
