import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TextField, Button } from "@mui/material";
import logo from "../img/nuovologogrande.png";
import { motion } from "framer-motion";

const theme = createTheme({
  palette: {
    primary: {
      main: "#b56376", // Imposta pink-400 come colore primario
    },
  },
});

const ContactForm = () => {


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="page"
    >
      <div className="select-none">
        <div className="flex justify-center">
          <img src={logo} alt="Indiefetto" />
        </div>
        <h1 className="font-semibold text-black animate-pulse">
          Contattami, ti risponderò al più presto!
        </h1>
        <ThemeProvider theme={theme}>
          <form
            className="max-w-lg flex flex-col justify-center mx-auto p-5"
            onSubmit={handleSubmit}
            action="https://formspree.io/f/mjvdjpbr"
            method="POST"
          >
            <TextField
              label="Nome"
              name="name"
              margin="normal"
              fullWidth
              required
            />
            <TextField
              label="Email"
              name="_replyto"
              type="email"
              margin="normal"
              fullWidth
              required
            />
            <TextField
              label="Messaggio"
              name="message"
              multiline
              margin="normal"
              fullWidth
              required
            />
            <div className="mt-5 w-full">
            <Button variant="contained" color="primary" type="submit">
              Invia
            </Button>
            </div>
          </form>
        </ThemeProvider>
      </div>
      {/* <div className="app">
      <h1>Sezione Commenti</h1>
      <CommentList comments={comments} />
      <CommentForm addComment={addComment} />
    </div> */}
    </motion.div>
  );
}

function handleSubmit(event) {
  event.preventDefault();
  const form = event.target;
  const data = new FormData(form);
  const xhr = new XMLHttpRequest();
  xhr.open(form.method, form.action);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      form.reset();
      alert(
        "Il tuo messaggio è stato inviato con successo. Grazie per avermi contattata. A presto!"
      );
    } else {
      alert(
        "Si è verificato un errore durante l'invio del messaggio. Perfavore riprova più tardi."
      );
    }
  };
  xhr.send(data);
}

export default ContactForm;
