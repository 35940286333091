import PixelStory from "./pixel";
import PixelBox from "./pixelimg";
import { motion } from 'framer-motion';

function Pixels() {
  return (
    <>
       <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="page"
    >
      <div className="shadow-lg m-5 shadow-zinc-900 border-2 border-gray-600">
        <PixelStory />
        <PixelBox />
      </div>
      </motion.div>
    </>
  );
}

export default Pixels;
