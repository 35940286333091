import React, { useState } from "react";
import img from '../img/oldicon.webp'
import audio from '../img/intel.mp3'

const AudioComponent = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    const audio = document.getElementById('audioElement');
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="">
      <img
        src={img}
        alt="Immagine"
        onClick={toggleAudio}
        style={{ cursor: 'pointer' }}
        className="mx-auto w-48"
      />
      <audio id="audioElement">
        <source src={audio} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioComponent;
