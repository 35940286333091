import React from "react";
import uomope from "../img/uomopefi.png";
import cent from "../img/cent3.png";
import atr from "../img/alessia_theRing.png";
import ktumov from "../img/ktumov.gif";
import magofanculo from "../img/magofanculo.gif";
import samor from "../img/samor2.png";

function PixelBox() {
  return (
    <div className="border-2 border-gray-400 bg-gradient-to-t from-black via-primarypink to-punk">
      <div className="flex-row md:grid-cols-2 gap-2">
        <div className="flex justify-center items-center">
        <img className="pixel" src={cent} alt=""/>
       
               {/* <motion.div
          drag
          dragConstraints={{ left: 0, right: 500, top: 0, bottom: 500 }}
        >
          Trascina questo elemento
        </motion.div> */}
       
          <img className="pixel animate-bounce hover:animate-none" src={ktumov} alt="" />
        </div>

        <div className="flex justify-center items-center">
          <img className="pixel" src={uomope} alt="" />
          <img
            className="pixel"
            src={magofanculo}
            alt=""
            style={{ borderRadius: "60%" }}
          />
        </div>
      </div>


      <div className="mb-20">
      <img src={samor} alt="" className="mx-auto"/>
        <img className="shadow-sm shadow-black mx-auto" src={atr} alt="" />
      </div>
      <div className="bc grayscale">
        Background credits: <br />
        <a
          href="https://commons.wikimedia.org/wiki/File:Computer-screen-code-glitch-animation-gif-background-free.gif"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wikimedia Commons
        </a>
      </div>
    </div>
  );
}

export default PixelBox;
