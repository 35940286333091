import "../style/general-module.css";
// import indiefetto from "../img/nuovologogrande.png";

const Card = () => {
  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true);
  //   }, 500);
  // }, []);

  return (
    <div
      className=/* {` */"flex flex-wrap items-center justify-center rounded-lg my-5" /* ${
        show ? "card slide-in" : ""
      }`} */
    >
      <div
        className="w-full lg:w-1/2 md:ml-1 card"
        // style={{ opacity: show ? 1 : 0 }}
      >
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-primarypink">
            Alessia Gaudioso
          </div>
          <div className="text-zinc-600 text-base">
            Front End Developer
            <br />
            Writer
            <p className="footer-icons text-zinc-700">
              <a
                href="https://www.facebook.com/alessia.gaudioso"
                aria-label="Facebook profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 float-left hover:text-primarypink hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/evitolaforma"
                aria-label="Instagram profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 float-left hover:text-primarypink hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/indiefetto"
                aria-label="LinkedIn profile"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-2 float-left hover:text-primarypink hover:cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </a>
            </p>
          </div>
          <div className="md:px-6 px-2 py-4">
            <h1 className="font-bold">Site built with:</h1>
            <span className="opacity-80 inline-block bg-zinc-700 px-3 py-1 text-sm font-semibold text-gray-50 mb-2 mt-1 mr-1 shadow-sm shadow-zinc-700">
              ReactJS
            </span>
            <span className="opacity-80 inline-block bg-zinc-700 px-3 py-1 text-sm font-semibold text-gray-50 shadow-sm mr-1 shadow-zinc-700">
              Tailwind CSS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
