import React from "react";

function PortfolioMenu() {
  return (
    <nav className="flex items-center justify-center py-4 mb-10">
      <ul className="flex space-x-4">
        <li className="cardm opacity-70 bg-primarypink p-1 shadow-sm shadow-primarypink">
          <a
            href="#skills"
            className="text-white text-md" 
          >Skills {/* <i className="fi fi-ss-caret-down"></i> */}
          </a>
        </li>
        <li className="cardm opacity-70 bg-primarypink p-1 shadow-sm shadow-primarypink">
          <a
            href="#cert"
            className="text-white rotate-3 text-md"
          >Certificazioni{/*  <i className="fi fi-ss-caret-down"></i> */}
          </a>
        </li>
       {/*  <li className="cardm opacity-70 bg-primarypink p-1 shadow-sm shadow-primarypink">
          <a
            href="#lab"
            className="text-white rotate-3 text-md"
          >
            I Lab <i className="fi fi-ss-caret-down"></i>
          </a>
        </li> */}
        <li className="cardm opacity-70 bg-primarypink p-1 shadow-sm shadow-primarypink">
          <a
            href="#prog"
            className="text-white rotate-3 text-md"
          >
            Progetti {/* <i className="fi fi-ss-caret-down"></i> */}
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default PortfolioMenu;

