function Policy() {
    return (
      <div className="text-left m-10 p-10 bg-punk rounded-md">
        <h1 className="text-left text-2xl font-bold">Privacy Policy</h1>
        <br />
        Questa Privacy Policy descrive come vengono raccolti, utilizzati e
        condivisi i dati personali degli utenti che utilizzano il nostro sito web.{" "}
        <br />
        Quando gli utenti compilano il formulario sul nostro sito, potremmo
        raccogliere le seguenti informazioni personali:
        <br />
        <ul>
          <li>Nome </li>
          <li>Email</li>
          <li>Numero di telefono</li>
          <li>Altre informazioni fornite volontariamente</li>
        </ul>
        <br />
        <h1 className="text-left">Utilizzo delle Informazioni</h1>
        <br />
        Le informazioni raccolte potrebbero essere utilizzate per: rispondere alle
        richieste degli utenti Fornire informazioni, aggiornamenti o risorse
        richieste.
        <br />
        <br />
        <h1 className="text-left">Protezione dei Dati</h1>
        <br /> La sicurezza dei dati dei nostri utenti è importante per noi.
        Adottiamo misure di sicurezza appropriate per proteggere le informazioni
        personali fornite al nostro sito. <br />
        <br />
        <h1 className="text-left">Condivisione delle Informazioni</h1>
        <br />
        Non vendiamo, scambiamo o trasferiamo in alcun modo le informazioni
        personali degli utenti a terzi senza il loro consenso. <br />
        <br />
        <h1 className="text-left">Cookie</h1>
        <br />
        Utilizziamo i cookie per migliorare l'esperienza degli utenti sul nostro
        sito. I cookie sono piccoli file di testo memorizzati sul computer
        dell'utente. L'utente può scegliere di accettare o rifiutare i cookie
        modificando le impostazioni del proprio browser.
        <br />
        <br />
        <h1 className="text-left">Modifiche alla Privacy Policy</h1>
        <br />
        La presente Privacy Policy può essere aggiornata periodicamente. Si
        consiglia agli utenti di controllare regolarmente questa pagina per essere
        informati su eventuali modifiche. <br />
        <br />
        <h1 className="text-left">Accettazione dei Termini</h1>
        <br /> Utilizzando il nostro sito, gli utenti accettano i termini della
        nostra Privacy Policy.<br/><br/><br/><br/>
        <h1 className="text-left text-2xl font-bold">Cookie Policy</h1>
        <br />
        Il nostro sito web utilizza cookie per migliorare l'esperienza degli
        utenti. I cookie sono piccoli file di testo memorizzati sul dispositivo
        dell'utente. Vengono utilizzati per fornire funzionalità di base del sito,
        analizzare il traffico e migliorare l'esperienza di navigazione.
        <br />
        <br />
        <h1 className="text-left">Tipi di Cookie</h1>
        <br />
        <ul>
          <li>
            Cookie Essenziali: Questi cookie sono necessari per il corretto
            funzionamento del sito e non possono essere disabilitati nelle nostre
            impostazioni. Servono, ad esempio, per mantenere l'utente autenticato
            durante la sessione.{" "}
          </li>
          <li>
            Cookie di Analisi: Utilizziamo cookie di terze parti per raccogliere
            informazioni sull'utilizzo del sito al fine di migliorare i nostri
            servizi. Questi cookie ci forniscono dati aggregati che non
            identificano personalmente gli utenti.
          </li>
          <li>
            Cookie di Preferenze: Questi cookie consentono al sito di memorizzare
            le preferenze dell'utente, come la lingua o le impostazioni di
            visualizzazione, per migliorare l'esperienza di navigazione.
          </li>
        </ul>
        <br />
  
        <h1 className="text-left">Gestione dei Cookie</h1>
        <br /> Gli utenti possono gestire le impostazioni dei cookie attraverso il
        proprio browser. È possibile accettare, rifiutare o eliminare i cookie
        dalle impostazioni del browser. Fare riferimento alla documentazione del
        browser per istruzioni su come gestire i cookie. <br />
        <br />
        <h1 className="text-left">Modifiche alla Cookie Policy</h1>
        <br />
        La presente Cookie Policy può essere aggiornata periodicamente. Si
        consiglia agli utenti di controllare regolarmente questa pagina per essere
        informati su eventuali modifiche. <br />
        <br />
        <h1 className="text-left">Accettazione dei Termini</h1>
        <br /> Utilizzando il nostro sito, gli utenti accettano i termini della
        nostra Cookie Policy.
      </div>
    );
  }
  
  export default Policy;
  