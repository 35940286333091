import React, { Fragment } from "react";
import Card from "./card";
import About from "./about";
import UltimeNotizie from "./news";
import indiefetto from "../img/nuovologogrande.webp";
import { motion } from "framer-motion";

const Landing = () => {
  // const dati = [
  //   {
  //     info1: 'Informazione 1',
  //     Colonna2: 'Dato2',
  //     Colonna3: 'Dato3',
  //     info2: <i className="fi fi-ss-angle-circle-down fi-lg mr-5 ico"></i>,
  //   },
  // ];

  // const images = [
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  //   "../nuovologogrande.png",
  // ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="page"
    >
      <Fragment>
        {/* <h1 className="text-center m-10 font-bold bg-gradient-to-r from-primarypink via-pink-300 to-black text-transparent bg-clip-text md:text-2xl hidden">
          Scrittura urbana e Sviluppo web
        </h1> */}

        <div className="w-fit mt-10 mx-auto">
          <motion.div
            initial={{ opacity: 0, x: 100 }} // Il logo inizierà fuori dalla vista, a destra
            animate={{ opacity: 1, x: 0 }} // Animazione per farlo apparire dalla destra verso il centro
            transition={{ duration: 0.5 }}
          >
            <img className="mx-auto" src={indiefetto} alt="Alessia Gaudioso" />
          </motion.div>
        </div>

        {/* <motion.div
          drag
          dragConstraints={{ left: 0, right: 500, top: 0, bottom: 500 }}
        >
          Trascina questo elemento
        </motion.div> */}

        <div
          className="flex flex-col items-center justify-center mx-10"
          id="hometop"
        >
          <div className="w-full sm:w-1/2 p-4">
            <Card />
          </div>
        </div>
        <About />

        {/* <div>
        <h1>La mia galleria immagini</h1>
        <ImageGallery images={images} />
      </div> */}

        <UltimeNotizie />
      </Fragment>
    </motion.div>
  );
};

export default Landing;
