import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./dropdown";
import { Helmet } from "react-helmet";
import logo from "../img/nuovologo.webp";

const Header = () => {
  return (
    <header className="flex justify-between items-center px-4 sm:px-8 select-none bg-[#efd2d6] shadow-sm shadow-[#efd2d6]">
      <Helmet>
        <meta
          name="description"
          content="Ciao! Sono una scrittrice e sviluppatrice web, ovvero una persona che scrive codice e parole, a volte contemporaneamente! Sul mio sito puoi trovare un mix di creatività e tecnologia, insieme a un pizzico di follia. Non prometto di non impazzire a volte, ma ti prometto un'esperienza unica e divertente. Entra nel mio mondo e lasciati sorprendere!"
        />
        <link rel="canonical" href="https://www.indiefetto.it" />
      </Helmet>
      <div className="flex items-center">
        <Link to="/">
          <img
            src={logo}
            className="w-16 my-5 mr-2 rounded-full hover:scale-105"
            alt="Indiefetto"
          />
        </Link>
        <span className="text-slate-50 font-bold text-lg hidden glow-text">
          Indiefetto
        </span>
      </div>
      <nav className="hidden sm:block">
        <ul className="flex space-x-6 text-zinc-700 font-bold">
          <NavItem to="/" text="Home" />
          <NavItem to="/portfolio" text="Portfolio" />
          <NavItem to="/servizi" text="Servizi" />
          <NavItem to="/libro" text="Il mio libro" />
          {/* <NavItem to="/collaboration" text="Il tuo libro" /> */}
          <NavItem to="/contatti" text="Contatti" />
        </ul>
      </nav>
      <Navbar />
      <div className="hidden sm:block">
        <a
          href="https://evitolaforma.altervista.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button text="Blog" />
        </a>
      </div>
    </header>
  );
};

const NavItem = ({ to, text }) => (
  <li className="hover:scale-110">
    <Link to={to}>{text}</Link>
  </li>
);

const Button = ({ text }) => (
  <button className="butbg text-gray-100 font-bold py-5 px-4 rounded-lg hover:scale-110">
    {text}
  </button>
);

export default Header;
