import ResumeSection from "./resume";
import Modal from "./modal";

const Utility = () => {
  return (
    <div className="flex justify-center mb-20">
      <div className="w-full">
        <h2 id="cert" className="text-2xl font-bold mb-10 mt-10">Licenze e certificanzioni</h2>
        <Modal />
        <ResumeSection />
      </div>
    </div>
  );
};

export default Utility;
