import './App.css';
import Header from './components/nav';
import Pagina1 from './components/landing';
import Portfolio from './components/portfolio';
import Libro from './components/libro';
import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Pixels from './components/pixelpag';
import ContactForm from './components/contatti';
import ServicesSection from './components/servizi';
import Policy from './components/policy'
import UpBtn from './components/upbtn'
// import CollaborativeWritingPage from './components/collaboration';


function App() {
  
  return (
    <Router>
      <div className="App">
        <Header />
        <div className='min-h-screen'>
        <Routes>
          <Route exact path="/*" element={<Pagina1 />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/libro" element={<Libro />} />
          <Route path="/pixel" element={<Pixels />} />
          {/* <Route path="/collaboration" element={<CollaborativeWritingPage />} /> */}
          <Route path="/servizi" element={<ServicesSection />} />
          <Route path="/contatti" element={<ContactForm />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
        <UpBtn />
        </div>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
