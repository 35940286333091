import React from "react";
import "../App.css";
import ProjectCard from "./projectcard";
import Costantino from "../img/costantinonuovo.png";
import Giuseppe from "../img/detommasirestyle.png";
import Colon from "../img/colon.png";
import Alex from '../img/lecasedialeloca.webp'
import enchanted from "../img/enchanted.png";
import enter from "../img/enter.jpg";
import Skills from "./skills";
import Feedbackg from "../img/feedback1.PNG";
import Feedbackc from "../img/feedback2.PNG";
import Utility from "./utility";
import Mui from "./muitest";
import PortfolioMenu from "./menuport";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const projects = [
    {
      id: 1,
      title: "Costantino Sanna",
      restyle:
        "Sito ricostrutito in React nel febbraio 2024.",
      image: Costantino,
      description:
        "Costantino è uno scrittore sardo, un visionario, ha posti nella mente dove rifugiarsi, sogni in cui riconoscersi, ricordi in cui specchiarsi, storie che gli scivolano dalle dita. Durante la realizzazione del sito gli ho fatto un'ora di intervista e in alcune cose ho scoperto che siamo simili. Ama follemente la sua terra: la Sardegna, della quale sembra amico e custode, odia la noia e la banalità.",
      technologies: ["ReactJS", "Tailwind CSS"],
      liveUrl: "https://www.costantinosanna.it",
      feedback: Feedbackc,
      // githubUrl: "https://github.com/example/project-1",
    },
    {
      id: 3,
      title: "Giuseppe De Tommasi",
      restyle:
        "Sito nato in HTML nel 2022, ricostrutito in React nel dicembre 2023.",
      image: Giuseppe,
      description:
        "Il primo ospite del mio progetto è Giuseppe de Tommasi, un Maestro Cartapestaio leccese che ha portato i suoi presepi in tutto il mondo, dando fregio al nostro paese e alla nobile arte della cartapesta.",
      technologies: ["ReactJS", "TypeScript", "Tailwind CSS"],
      liveUrl: "https://www.giuseppedetommasi.it",
      // githubUrl: "https://github.com/example/project-2",
      feedback: Feedbackg,
    },
    {
      id: 4,
      title: "Enchanted Team",
      image: enchanted,
      description:
        "Una coppia fantastica, due ragazzi coraggiosi che respirano arte e che per essa sono disposti a rischiare tutto.",
      technologies: ["ReactJS", "Tailwind CSS"],
      liveUrl: "https://enchantedteam.altervista.org/",
      // githubUrl: "https://github.com/example/project-2",
      feedback: Feedbackg,
    },

   
    {
      id: 2,
      title: "Colostomia 2.0",
      image: Colon,
      description:
        "Un giovane programmatore, durante l'ennesima giornata in ufficio, preda della quotidiana noia, provò a reindirizzare nell'interfaccia audio del suo sistema Linux un file di testo con il quale aveva giornalmente a che fare. Generò così un rumore sgradevole che ricordava quello di una vecchia stampante...",
      technologies: ["ReactJS", "Tailwind CSS"],
      liveUrl: "https://colostomia.altervista.org",
      // githubUrl: "https://github.com/example/project-2",
    },
  ];

  const professionalProjects = [
    {
      id: 1,
      title: "Le case vacanze di Alex",
      image: Alex,
      description: "Serve una fuga dalla routine? Alex è pronto ad accoglierti a braccia aperte in una delle sue meravigliose case.",
      technologies: ["ReactJS", "Tailwind CSS"],
      liveUrl: "https://www.lecasedialex.it",
    },
   
  ];

  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolla verso l'alto quando si cambia la route
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="page"
    >
      <div className="p-4 md:p-8 select-none">
        <PortfolioMenu />
        {/* <CardPort /> */}
        <Skills />
        <Utility />
        {/* <CardLab
            title="Indiefetto Lab"
            subtitle="Live Lab"
            labLink="https://indiefetto.altervista.org/"
            githubLink="https://github.com/alecodemaster/indiefetto"
          /> */}

<div className="pt-8 origin-bottom mx-auto md:text-center rounded-md bg-gray-100 mb-10 shadow-inner">
          <h1 
          id="prog"
          className="font-bold text-primarypink text-center text-2xl">
            Professional Projects
          </h1>
          <div className="flex flex-wrap justify-center lg:justify-start">
            {professionalProjects.map((project) => (
              <div
                key={project.id}
                className="w-full xl:w-1/4 sm:w-1/2 px-7 mb-10 mx-auto mt-5 flex-shrink-0"
              >
                <ProjectCard project={project} />
              </div>
            ))}
          </div>
        </div>

        <div className="pt-8 origin-bottom mx-auto bg-[#efd2d6] shadow-inner text-left md:text-center rounded-md">
        <h1 className="font-bold text-primarypink text-center text-2xl">Get a light</h1>
          <p className="p-5 md:w-1/2 mx-auto">   
            Tanti artisti svaniscono, lontani dalle vetrine virtuali: scrittori,
            musicisti, pittori, scultori... nascosti tra le pieghe di un paese
            dove i musicisti son solo quelli dei talent e i libri li scrivono
            gli influencer.
            <br />
            Vorrei dare loro una vetrina, provare ad illuminarla, e poi
            chissà... la cosa più importante sarà la collaborazione e divertirsi
            creando insieme qualcosa. <br />
            <br />
            Tutti i progetti saranno in costante evoluzione, più imparo, più
            applico.
            <br />
            <br />
            Stay tuned!
          </p>

          
          <div
            className="flex flex-wrap justify-center lg:justify-start"
          >
            {projects.map((project) => (
              <div
                key={project.id}
                className="w-full xl:w-1/4 sm:w-1/2 px-7 mb-4 flex-shrink-0"
              >
                <ProjectCard project={project} />
              </div>
            ))}
          </div>
        </div>

        {/* <div class="flex flex-wrap justify-center">
          {/* <div class="p-4">

                    <a
                        href="https://www.behance.net/alessiagaudioso"
                        target={'_blank'}
                        class=" bg-primarypink mt-10 text-gray-50 py-2 px-4 rounded-lg block text-center shadow-md shadow-zinc-900 hover:bg-zinc-700">
                        Behance profile

                    </a>
                </div>
        </div> */}

        <Mui />

        <button
          onClick={() => handleLinkClick("/pixel")}
          className="text-white hover:text-primarypink pb-2"
        >
          <img
            className="mt-5 grayscale hover:grayscale-0 brightness-50 rounded-md shadow-md shadow-zinc-800 animate-bounce"
            src={enter}
            alt="Project"
          />
        </button>
      </div>
    </motion.div>
  );
}

export default Portfolio;
